window['dataLayer'] = window['dataLayer'] || [];
window['gtag'] = function () {
  window['dataLayer'].push(arguments);
};
window['gtag']('js', new Date());

if (window['GOOGLE_ANALYTICS_ID']) {
  window['gtag']('config', window['GOOGLE_ANALYTICS_ID']);
}

let isInitialLoad = true;
document.addEventListener('turbolinks:load', (event: any) => {
  if (window['GOOGLE_ANALYTICS_ID']) {
    window['gtag']('config', window['GOOGLE_ANALYTICS_ID'], {
      page_location: event.data.url,
    });
  }
});

document.addEventListener('turbolinks:load', (_event) => {
  if (window['HUBSPOT_ID']) {
    const hsScript = document.querySelector('#hs-script-loader');
    if (hsScript) {
      hsScript.remove();
      window['hubspot_live_messages_running'] = undefined;
      window['HubSpotConversations'] = undefined;
    }

    const hsScriptLoaders = document.querySelectorAll(
      'script[data-loader="hs-scriptloader"]'
    );
    hsScriptLoaders.forEach((script) => script.remove());

    const hsAnalytics = document.getElementById('hs-analytics');
    if (hsAnalytics) {
      hsAnalytics.remove();
    }

    const script = document.createElement('script');
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = `https://js-na1.hs-scripts.com/${window['HUBSPOT_ID']}.js`;
    document.querySelector('head').appendChild(script);
  }
});
