import Turbolinks from 'turbolinks';
Turbolinks.start();

import 'bootstrap';
import * as Carousel from 'bootstrap/js/dist/carousel';
import PhotoSwipe from '../../vendor/photoswipe/photoswipe.js';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import LazyLoad from 'vanilla-lazyload';

import './tracking';

import 'stylesheets/spree/frontend';

let toggleNewsletterTimeout: number;

window['dataLayer'] = window['dataLayer'] || [];
window['gtag'] = function () {
  window['dataLayer'].push(arguments);
};
window['gtag']('js', new Date());

document.addEventListener('DOMContentLoaded', (event) => {
  new LazyLoad({
    elements_selector: `.lazy`,
    use_native: true,
  });

  new LazyLoad({
    elements_selector: `.lazy-bg`,
  });
});

document.addEventListener('turbolinks:load', function (event) {
  if (window['GOOGLE_ANALYTICS_ID']) {
    window['gtag']('config', window['GOOGLE_ANALYTICS_ID'], {
      page_location: (event as any).data.url,
    });
  }

  const homeCarousel: HTMLDivElement = document.querySelector('#home-carousel');
  if (homeCarousel) {
    const carousel = new Carousel(homeCarousel, {
      interval: 3000,
      pause: false,
    });
    carousel.cycle();
  }

  new LazyLoad({
    elements_selector: `.lazy`,
    use_native: true,
  });

  new LazyLoad({
    elements_selector: `.lazy-bg`,
  });

  document
    .querySelectorAll('.pswp__container')
    .forEach((element: HTMLDivElement) =>
      element.addEventListener('contextmenu', (ev: MouseEvent) =>
        ev.preventDefault()
      )
    );

  const topNav: HTMLElement = document.querySelector('#top-nav');
  const topNavDark = topNav.classList.contains('navbar-dark');
  document
    .querySelectorAll('[data-toggle-target]')
    .forEach((element: HTMLElement) => {
      element.addEventListener('click', (e: MouseEvent) => {
        e.preventDefault();

        const target: HTMLElement = document.querySelector(
          element.dataset['toggleTarget']
        );
        onToggleTarget(element, target, topNav, topNavDark);
      });
    });

  window.addEventListener('scroll', (_ev: Event) => {
    const scroll = window.scrollY;

    if (scroll > 0) {
      topNav.classList.add('scrolled');
    } else {
      topNav.classList.remove('scrolled');
    }
  });

  document
    .querySelectorAll('[data-autoscrolldown-target]')
    .forEach((element: HTMLElement) => {
      const target: HTMLDivElement = document.querySelector(
        element.dataset['autoscrolldownTarget']
      );
      const elementParent = element.parentElement;
      if (
        elementParent.classList.contains('d-lg-none') &&
        target.clientHeight < target.scrollHeight
      ) {
        elementParent.classList.remove('d-lg-none');
      }

      element.addEventListener('click', (e: MouseEvent) => {
        e.preventDefault();

        target.scrollBy({ left: 0, top: 200, behavior: 'smooth' });
      });
    });

  if (!localStorage.getItem('disableAutoNewsletter')) {
    let newsletterTimeout: number;

    if (toggleNewsletterTimeout) {
      clearTimeout(toggleNewsletterTimeout);
      newsletterTimeout = 5000;
    } else {
      newsletterTimeout = 10000;
    }

    toggleNewsletterTimeout = window.setTimeout(() => {
      onToggleTarget(
        document.querySelector('#nav-newsletter'),
        document.querySelector('#newsletter-subscription'),
        topNav,
        topNavDark
      );
      toggleNewsletterTimeout = undefined;
    }, newsletterTimeout);
  }
});

const onToggleTarget = (
  element: HTMLElement,
  target: HTMLElement,
  topNav?: HTMLElement,
  topNavDark?: boolean
) => {
  if (target.classList.contains('d-flex')) {
    setTimeout(() => {
      target.classList.remove('d-flex');
      target.classList.add('d-none');
      element.setAttribute('aria-expanded', 'false');
    }, 500);
    target.classList.remove('show');

    if (element.dataset['toggleTarget'] == '#side-nav') {
      element.parentElement.parentElement.firstElementChild.classList.remove(
        'opacity-0'
      );

      if (topNavDark) {
        topNav.classList.remove('navbar-light');
        topNav.classList.add('navbar-dark');
      }
    }

    if (element.dataset['toggleTarget'] == '#newsletter-subscription') {
      localStorage.setItem('disableAutoNewsletter', 'true');
    }
  } else {
    target.classList.remove('d-none');
    target.classList.add('d-flex');
    setTimeout(() => {
      target.classList.add('show');
      element.setAttribute('aria-expanded', 'true');
    }, 10);

    if (element.dataset['toggleTarget'] == '#side-nav') {
      element.parentElement.parentElement.firstElementChild.classList.add(
        'opacity-0'
      );

      if (topNavDark) {
        topNav.classList.remove('navbar-dark');
        topNav.classList.add('navbar-light');
      }
    }
  }
};

const onGalleryThumbnailClick = (
  element: HTMLAnchorElement,
  e: Event,
  index: number
) => {
  e.preventDefault();

  if (!element.classList.contains('selected')) {
    const galleryThumbnails = document.querySelectorAll('.gallery .thumbnail');
    if (galleryThumbnails.length > 0) {
      galleryThumbnails.forEach(function (thumbnail) {
        thumbnail.classList.remove('selected');
      });
      element.classList.add('selected');

      const galleryImage: HTMLAnchorElement =
        document.querySelector('.gallery .main');
      if (galleryImage) {
        galleryImage.setAttribute(
          'style',
          `background-image: url('${element.href}')`
        );
        galleryImage.dataset.index = `${index}`;
      }
    }
  }
};
window['onGalleryThumbnailClick'] = onGalleryThumbnailClick;

const onGalleryImageClick = (
  element: HTMLAnchorElement,
  event: Event,
  index?: number
) => {
  event.preventDefault();

  const pswpElement = document.querySelectorAll('.pswp')[0];
  if (pswpElement) {
    const options = {
      index: index || parseInt(element.dataset.index),
      // history: false
    };

    const gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUI_Default,
      window['pswpItems'],
      options
    );
    gallery.init();
  }
};
window['onGalleryImageClick'] = onGalleryImageClick;

const onProductVariantSelect = (element: HTMLSelectElement) => {
  const variantPricesList: NodeListOf<HTMLDivElement> =
    document.querySelectorAll('.product-price');
  variantPricesList.forEach((variantPrices) => {
    variantPrices.classList.remove('d-block');
    variantPrices.classList.add('d-none');
  });

  const selectedVariantPrice: HTMLDivElement = document.querySelector(
    `.product-price-${element.value}`
  );
  if (selectedVariantPrice) {
    selectedVariantPrice.classList.remove('d-none');
    selectedVariantPrice.classList.add('d-block');
  }

  const variantPropertiesList: NodeListOf<HTMLDivElement> =
    document.querySelectorAll('.variant-properties');
  variantPropertiesList.forEach((variantProperties) => {
    variantProperties.classList.remove('d-block');
    variantProperties.classList.add('d-none');
  });

  const selectedVariantProperties: HTMLDivElement = document.querySelector(
    `.variant-properties-${element.value}`
  );
  if (selectedVariantProperties) {
    selectedVariantProperties.classList.remove('d-none');
    selectedVariantProperties.classList.add('d-block');
  }
};
window['onProductVariantSelect'] = onProductVariantSelect;

const onProductMaterialSelect = (element: HTMLSelectElement) => {
  const materialSizesList: NodeListOf<HTMLDivElement> =
    document.querySelectorAll('.product-material-sizes');
  materialSizesList.forEach((materialSizes) => {
    materialSizes.classList.remove('d-block');
    materialSizes.classList.add('d-none');
  });

  const selectedMaterialSizes: HTMLDivElement = document.querySelector(
    `.product-material-sizes-${element.selectedIndex}`
  );
  if (selectedMaterialSizes) {
    selectedMaterialSizes.classList.remove('d-none');
    selectedMaterialSizes.classList.add('d-block');
  }

  onProductVariantSelect(selectedMaterialSizes.querySelector('select'));
};
window['onProductMaterialSelect'] = onProductMaterialSelect;

const onTestimonialClick = (
  element: HTMLAnchorElement,
  e: Event,
  index: number
) => {
  e.preventDefault();

  if (!element.classList.contains('selected')) {
    const testimonialCards = document.querySelectorAll(
      '#testimonials .testimonial-card'
    );
    if (testimonialCards.length > 0) {
      testimonialCards.forEach(function (testimonialCard) {
        testimonialCard.classList.remove('selected');
      });
      element.classList.add('selected');
    }

    const testimonialContents = document.querySelectorAll(
      '#testimonials .testimonial-content'
    );
    if (testimonialContents.length > 0) {
      testimonialContents.forEach(function (testimonialContent) {
        testimonialContent.classList.add('d-none');
      });

      const selected = testimonialContents.item(index);
      selected.classList.remove('d-none');
      selected.firstElementChild.scrollTop = 0;

      if (
        selected.firstElementChild.clientHeight <
        selected.firstElementChild.scrollHeight
      ) {
        selected.lastElementChild.classList.remove('d-lg-none');
      }
    }
  }
};
window['onTestimonialClick'] = onTestimonialClick;

const onOpenNewsletter = (e: Event) => {
  e.preventDefault();

  console.log('Open newsletter!');
};
window['onOpenNewsletter'] = onOpenNewsletter;
